a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-shadow: 0px 0px 2px #d3d3d3;
}

.MuiButton-root.MuiButton-contained{
  margin: auto 0px 0px;
}

.leaflet-container{
  z-index: 1
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar:horizontal {
  width: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
